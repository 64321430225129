import { SET_CURRENCY } from "../actions/currencyActions";

const initState = {
  currencySymbol: "฿",
  currencyName: "บาท",
  currencyRate: 1
};

const currencyReducer = (state = initState, action) => {
  if (action.type === SET_CURRENCY) {
    const currencyName = action.payload.currencyName;

    if (currencyName === "บาท") {
      return {
        ...state,
        currencySymbol: "฿",
        currencyRate: action.payload.currencyRate,
        currencyName
      };
    }
  }

  return state;
};

export default currencyReducer;
